import {
  AlignmentOptions,
  ButtonStyleOptions,
  CategoriesType,
  CategoryLayoutOptions,
  DockLocationOptions,
  FilterByOptions,
  ImageAndTextRatioOptions,
  ImagePositionOptions,
  ImageResizeOptions,
  ImageShapeOptions,
  MobileSettingsKeys,
  OfferingListLayoutOptions,
  SettingsKeys,
} from '../../Shared/appKeys/SettingsKeys';

export interface OfferingListWidgetDisplayOptions {
  multiOfferingsDisplayOptions: MultiOfferingsDisplayOptions;
  offeringDisplayOptions: OfferingDisplayOptions;
}

interface FilterOptions {
  filterBy: FilterByOptions;
  selectedLocations: string[];
}

export const MAX_FONT_SIZE_MEDIUM_BUTTON = 18;

export interface MultiOfferingsDisplayOptions {
  layout: OfferingListLayoutOptions;
  title: TitleDisplayOptions;
  isTitleVisible: boolean;
  titleAlignment: AlignmentOptions;
  categoriesDisplayOptions: CategoriesDisplayOptions;
  spaceBetweenOfferings: number;
  stripSpaceBetweenOfferings: number;
  cardsSpacing: number;
  dividerWidth: number;
  isDividerVisible: boolean;
  maxItemsPerRow: number;
  filterOptions: FilterOptions;
}

export interface TitleDisplayOptions {
  text: string;
  htmlTag: string;
}
export interface CategoriesDisplayOptions {
  isCategoriesVisible: boolean;
  categoriesType: CategoriesType;
  categoriesContentAlignment: AlignmentOptions;
  isCategoriesContentStretch: boolean;
  layout: CategoryLayoutOptions;
  isShowAllServicesCategory: boolean;
  allServicesCategoryText: string;
}

export interface OfferingDisplayOptions {
  isOfferingTagLineVisible: boolean;
  isOfferingDividerVisible: boolean;
  isOfferingDurationVisible: boolean;
  isOfferingPriceVisible: boolean;
  isOfferingOfferedDaysVisible: boolean;
  isOfferingStartDateVisible: boolean;
  isOfferingBookButtonVisible: boolean;
  isOfferingMoreInfoLabelVisible: boolean;
  isOfferingOnlineIndicationVisible: boolean;
  isOfferingImageVisible: boolean;
  isLargeButton: boolean;
  isSecondaryButton: boolean;
  offeringImageResize: ImageResizeOptions;
  offeringImageShape: ImageShapeOptions;
  offeringImageDockLocation: DockLocationOptions;
  offeringTextAlignment: AlignmentOptions;
  layout: OfferingListLayoutOptions;
  buttonStyle: ButtonStyleOptions;
  bookActionText: string;
  noBookActionText: string;
  pendingApprovalActionText: string;
  moreInfoLabelText: string;
  onlineIndicationText: string;
  ratio: ImageAndTextRatioOptions;
  ratioFlipped: boolean;
  imagePosition: ImagePositionOptions;
  titleHtmlTag: string;
  tagLineHtmlTag: string;
  detailsHtmlTag: string;
}

export function getDisplayOptions(
  settingsData,
  isMobile = false,
  isMultiLocationEnabled = false,
): OfferingListWidgetDisplayOptions {
  settingsData = settingsData || {};
  return {
    multiOfferingsDisplayOptions: {
      layout: settingsData[SettingsKeys.OFFERING_LIST_LAYOUT],
      title: {
        text: settingsData[SettingsKeys.MULTI_OFFERINGS_TITLE_TEXT],
        htmlTag:
          settingsData[SettingsKeys.MULTI_OFFERINGS_TITLE_FONT]?.htmlTag ||
          'h1',
      },
      isTitleVisible: settingsData[SettingsKeys.DISPLAY_MULTI_OFFERINGS_TITLE],
      titleAlignment:
        settingsData[SettingsKeys.MULTI_OFFERINGS_TITLE_ALIGNMENT],
      categoriesDisplayOptions: {
        isCategoriesVisible: settingsData[SettingsKeys.DISPLAY_CATEGORIES],
        categoriesType: settingsData[SettingsKeys.CATEGORIES_TYPE],
        categoriesContentAlignment:
          settingsData[SettingsKeys.CATEGORY_ALIGNMENT],
        isCategoriesContentStretch:
          settingsData[SettingsKeys.FIT_CATEGORY_WIDTH],
        layout: isMobile
          ? settingsData[MobileSettingsKeys.MOBILE_CATEGORY_LAYOUT_OPTION]
          : settingsData[SettingsKeys.CATEGORY_LAYOUT_OPTION],
        isShowAllServicesCategory:
          settingsData[SettingsKeys.CATEGORY_ALL_SERVICES_SHOW],
        allServicesCategoryText:
          settingsData[SettingsKeys.CATEGORY_ALL_SERVICES_TEXT],
      },
      spaceBetweenOfferings: settingsData[SettingsKeys.SPACE_BETWEEN_OFFERINGS],
      stripSpaceBetweenOfferings:
        settingsData[SettingsKeys.STRIP_SPACE_BETWEEN_OFFERINGS],
      cardsSpacing: isMobile
        ? settingsData[MobileSettingsKeys.MOBILE_CARDS_SPACING]
        : settingsData[SettingsKeys.CARDS_SPACING],
      dividerWidth: settingsData[SettingsKeys.SERVICE_DIVIDER_WIDTH],
      maxItemsPerRow: settingsData[SettingsKeys.LAYOUT_CARDS_PER_ROW],
      isDividerVisible: settingsData[SettingsKeys.DISPLAY_SERVICE_DIVIDER],
      filterOptions: {
        filterBy: isMultiLocationEnabled
          ? settingsData[SettingsKeys.FILTER_BY]
          : FilterByOptions.BY_SERVICES,
        selectedLocations: isMultiLocationEnabled
          ? settingsData[SettingsKeys.SELECTED_LOCATIONS]
          : [],
      },
    },
    offeringDisplayOptions: {
      isOfferingTagLineVisible: settingsData[SettingsKeys.DISPLAY_TAG_LINE],
      isOfferingDividerVisible: settingsData[SettingsKeys.DISPLAY_DIVIDER],
      isOfferingDurationVisible: settingsData[SettingsKeys.DISPLAY_DURATION],
      isOfferingPriceVisible: settingsData[SettingsKeys.DISPLAY_PRICE],
      isOfferingOfferedDaysVisible:
        settingsData[SettingsKeys.DISPLAY_DAYS_OFFERED],
      isOfferingStartDateVisible: settingsData[SettingsKeys.DISPLAY_START_DATE],
      isOfferingBookButtonVisible: settingsData[SettingsKeys.DISPLAY_BUTTON],
      isOfferingMoreInfoLabelVisible:
        settingsData[SettingsKeys.DISPLAY_MORE_INFO_LABEL],
      isOfferingOnlineIndicationVisible:
        settingsData[SettingsKeys.DISPLAY_ONLINE_INDICATION],
      isOfferingImageVisible: settingsData[SettingsKeys.DISPLAY_IMAGE],
      offeringImageResize:
        settingsData[
          isMobile
            ? MobileSettingsKeys.MOBILE_IMAGE_RESIZE_OPTION
            : SettingsKeys.IMAGE_RESIZE_OPTION
        ],
      offeringImageShape: settingsData[SettingsKeys.IMAGE_SHAPE_OPTION],
      offeringImageDockLocation:
        settingsData[
          isMobile
            ? MobileSettingsKeys.MOBILE_PIN_LOCATION
            : SettingsKeys.PIN_LOCATION
        ],
      isLargeButton: false,
      isSecondaryButton:
        settingsData[SettingsKeys.BUTTON_STYLE] ===
          ButtonStyleOptions.SQUARE_HOLE ||
        settingsData[SettingsKeys.BUTTON_STYLE] ===
          ButtonStyleOptions.CIRCLE_HOLE,
      offeringTextAlignment: settingsData[SettingsKeys.TEXT_ALIGNMENT],
      layout: settingsData[SettingsKeys.OFFERING_LIST_LAYOUT],
      buttonStyle: settingsData[SettingsKeys.BUTTON_STYLE],
      bookActionText: settingsData[SettingsKeys.BOOK_FLOW_ACTION_TEXT],
      noBookActionText: settingsData[SettingsKeys.NO_BOOK_FLOW_ACTION_TEXT],
      pendingApprovalActionText:
        settingsData[SettingsKeys.PENDING_APPROVAL_FLOW_ACTION_TEXT],
      moreInfoLabelText: settingsData[SettingsKeys.MORE_INFO_LABEL_TEXT],
      onlineIndicationText: settingsData[SettingsKeys.ONLINE_INDICATION_TEXT],
      ratio: settingsData[SettingsKeys.IMAGE_AND_TEXT_RATIO],
      ratioFlipped: settingsData[SettingsKeys.IMAGE_AND_TEXT_RATIO_IS_FLIPED],
      imagePosition: settingsData[SettingsKeys.IMAGE_POSITION_OPTION],
      titleHtmlTag:
        settingsData[SettingsKeys.OFFERING_NAME_FONT]?.htmlTag || 'h3',
      tagLineHtmlTag:
        settingsData[SettingsKeys.OFFERING_TAGLINE_FONT]?.htmlTag || 'p',
      detailsHtmlTag:
        settingsData[SettingsKeys.OFFERING_DETAILS_FONT]?.htmlTag || 'p',
    },
  };
}
